import React from "react";
import "./styles/video.css";
import Triangle from "../components/Triangle";
import { useLanguageContext } from "../../context/languageContext";

function Video({ page, triangle = false, quote = false, side = "left" }) {
  const { t } = useLanguageContext();
  side = side === "right" ? "right" : "left";
  return (
    <section>
      <div className="landing-video-div">
        {triangle && <Triangle page={page} quote={quote} side={side} />}
        <div className="video-embed">
          <iframe
            title={`${t(`${page}.vimeoID`)}`}
            src={`https://player.vimeo.com/video/${t(
              `${page}.vimeoID`,
            )}?h=b5aaecad8d&title=0&byline=0&portrait=0`}
            width="640"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
  );
}
export default Video;
