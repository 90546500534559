import React, { useState, useEffect } from "react";
import { Modal, Button, Accordion, Container, Form } from "react-bootstrap";
import { useLanguageContext } from "../../context/languageContext";

function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie =
    name + "=" + (value || "") + expires + "; path=/; SameSite=Lax";

  if (window.dataLayer) {
    window.dataLayer.push({
      event: "cookieConsentUpdate",
    });

    if (
      value.analytics_storage &&
      value.ad_storage &&
      value.ad_user_data &&
      value.ad_personalization
    ) {
      window.dataLayer.push({
        event: "cookieConsentGrantedGoogle",
      });
      window.dataLayer.push({
        event: "cookieConsentGrantedFacebook",
      });
    }
  }
}

function getCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function CookieConsent() {
  const { t } = useLanguageContext();
  // Extract manage_cookies from the URL query parameters
  const searchParams = new URLSearchParams(window.location.search);
  const manageCookies = searchParams.get("manage-cookies");
  // Initialize state directly from cookies
  const [showModal, setShowModal] = useState(manageCookies === "1");
  const [showConsent, setShowConsent] = useState(
    !getCookie("cookieConsent") || manageCookies === "1",
  );
  const initialCookies = () => {
    const cookieConsent = getCookie("cookieConsent");
    return cookieConsent
      ? JSON.parse(cookieConsent)
      : {
          required: true,
          analytics_storage: false,
          ad_storage: false,
          ad_user_data: false,
          ad_personalization: false,
        };
  };
  const [cookies, setCookies] = useState(initialCookies);

  // Load initial cookie settings
  useEffect(() => {
    const cookieConsent = getCookie("cookieConsent");
    if (cookieConsent) {
      setCookies(JSON.parse(cookieConsent));
    }
  }, []);

  // Update browser cookies whenever state changes
  useEffect(() => {
    setCookie("cookieConsent", JSON.stringify(cookies), 365);
  }, [cookies]);

  const toggleCookie = (cookie, event) => {
    event.stopPropagation(); // Prevent accordion from toggling
    setCookies((prevCookies) => ({
      ...prevCookies,
      [cookie]: !prevCookies[cookie],
    }));
  };

  const acceptAllCookies = () => {
    const allAccepted = {
      required: true,
      ad_storage: true,
      ad_user_data: true,
      ad_personalization: true,
      analytics_storage: true,
    };
    setCookies(allAccepted);
    setShowModal(false);
    setShowConsent(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowConsent(false);
  };

  if (!showConsent) {
    return null;
  }

  return (
    <Container
      className="cookie-consent"
      style={{
        padding: "20px",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "rgba(19, 18, 44, 0.9)",
        color: "white",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <p>{t("cookies.paragraph1")}</p>
      <div>
        <Button variant="primary" onClick={acceptAllCookies}>
          {t("cookies.acceptAll")}
        </Button>
        <Button variant="info" onClick={() => setShowModal(true)}>
          {t("cookies.manageCookies")}
        </Button>
      </div>
      <Modal
        className="manage-cookie-modal"
        show={showModal}
        onHide={handleCloseModal}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("cookies.cookiePreferences")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("cookies.paragraph2")}</p>
          <p></p>
          <Button variant="primary" onClick={acceptAllCookies} className="me-2">
            {t("cookies.acceptAll")}
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t("cookies.savePreferences")}
          </Button>
          <Accordion defaultActiveKey="0">
            {Object.entries(cookies).map(([key, value], index) => (
              <Accordion.Item eventKey={index.toString()} key={key}>
                <Accordion.Header>
                  <div
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      display: "flex",
                    }}
                  >
                    {t(`cookies.${key}`).toString().toUpperCase()}
                    <Form.Switch
                      id={key}
                      label={value ? t("cookies.on") : t("cookies.off")}
                      checked={value}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => toggleCookie(key, e)}
                      disabled={key === "required"}
                    />
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {t(`cookies.${key}Description`)}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default CookieConsent;
