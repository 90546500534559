import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import useSequentialScripts from "../../hooks/useSequentialScripts";
import "../styles/home.css";
import { useLanguageContext } from "../../context/languageContext";
import ContactSlide from "../../global/ContactSlide";
import "../../global/styles/contactSlide.css";



function Home() {
  useSequentialScripts([
    "/js/jquery.min.js",
    "/js/jquery.viewportchecker.min.js",
    "/js/jquery.mixitup.min.js",
    "/js/main.js",
  ]);

  const { t } = useLanguageContext();

  return (
    <>
      <Helmet>
        <title>Home | Who Am I?</title>
      </Helmet>
      <section style={{ backgroundColor: "#13122c" }}>
        <div className="container home-text">
          <div className="row">
            <p>{t("home.paragraph1")}</p>

            <p className="home-bold">{t("home.paragraph2")}</p>

            <p>{t("home.paragraph3")}</p>

            <p>{t("home.paragraph4")}</p>

            <p className="home-bold">
              {t("home.callToAction.opening")}{" "}
              <Link to="i-am-the-way">{t("home.callToAction.theWay")}</Link>
              {t("home.callToAction.comma1")}{" "}
              <Link to="i-am-the-light">{t("home.callToAction.theLight")}</Link>
              {t("home.callToAction.comma2")}{" "}
              <Link to="i-am-the-vine">{t("home.callToAction.theVine")}</Link>
              {t("home.callToAction.final")}
            </p>

            <ContactSlide format="button" buttonText={t("contact.contactUs")} />
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
