import React from "react";
import { Link } from "react-router-dom";
import logo from "../../logo75.png";
import { useLanguageContext } from "../../context/languageContext";
import { isRtl } from "../isRtl";
import MobileMenu from "../components/MobileMenu";
import LanguageSlide from "../LanguageSlide";
import ContactSlide from "../ContactSlide";

const Header = ({ large, page }) => {
  const headerClass = large ? "header-1" : "header-small";
  const { t } = useLanguageContext();
  return (
    <>
      {/* Header */}
      {/* Section Start - Header */}
      <section className={`header bg-lightgray ${headerClass}`}>
        {/* Menu Top Bar - Start */}
        <div className="topbar " data-effect="fadeIn">
          <div
            className="primary inviewport animated delay4"
            data-effect="fadeInRightBig"
          >
            <div className="menu-desktop cssmenu">
              <ul className="menu-ul">
                <li>
                  <Link to="/i-am-the-way">{t("mainNav.theWay")}</Link>
                </li>
                <li>
                  <Link to="/i-am-the-light">{t("mainNav.theLight")}</Link>
                </li>
                <li>
                  <Link to="/i-am-the-vine">{t("mainNav.theVine")}</Link>
                </li>
                <li>
                  <Link to="/explore">{t("mainNav.explore")} </Link>
                </li>
                <li className="has-sub">
                  <Link to="#">
                    {t("mainNav.resources")}{" "}
                    <i className="mdi mdi-chevron-down"></i>
                  </Link>
                  <ul>
                    <li>
                      <a href="https://www.bible.com/app">
                        {t("global.bibleDownload")}
                      </a>
                    </li>
                    <li>
                      <a href="/vr-experience">{t("global.vrExperience")}</a>
                    </li>
                  </ul>
                </li>
                <li className="contactLink">
                  <ContactSlide />
                </li>
                <LanguageSlide />
              </ul>
            </div>
          </div>
        </div>
        {/* Menu Top Bar - End */}
        {/* Logo and Mobile Menu - Start */}
        <div className="header-logo-wrap">
          <div className="container">
            <div className="logo col-xs-2">
              <Link to="/">
                <img src={logo} alt="Logo" />
              </Link>
            </div>
            <MobileMenu />
          </div>
        </div>
        {/* Logo and Mobile Menu - End */}
        {/* Header Slide - Start */}
        <div className="header-slide" style={{ position: "relative" }}>
          <img
            alt="header"
            srcSet={`img/splash-${page}-480.webp 480w, img/splash-${page}-960.webp 960w, img/splash-${page}.webp 2000w`}
            sizes="
              (max-width: 480px) 100vw, 
              (max-width: 960px) 100vw,
              961px"
            src={`img/splash-${page}-480.webp`}
            className="header-img"
            width="2000"
            height="1414"
            loading="lazy"
          />
          <div className="overlay overlay1">
            <div
              className="black inviewport animated delay4"
              data-effect="fadeInLeftOpacity"
            ></div>
            <div
              className="primary inviewport animated delay4"
              data-effect="fadeInRightOpacity"
            ></div>
            {/* Header Text - Start */}
            <div className="maintext">
              <div
                className="primary-text inviewport animated delay4"
                data-effect="fadeInRightBig"
              >
                <div className="left-line">
                  {isRtl() ? <h4>&nbsp;</h4> : <h4>{t(`${page}.heading1`)}</h4>}
                  {isRtl() ? (
                    <h1>{t(`${page}.heading3`)}</h1>
                  ) : (
                    <h1>{t(`${page}.heading2`)}</h1>
                  )}
                </div>
              </div>
              <div
                className="black-text inviewport animated delay4"
                data-effect="fadeInLeftBig"
              >
                <div>
                  {isRtl() && <h4>{t(`${page}.heading1`)}</h4>}
                  {isRtl() ? (
                    <h1>{t(`${page}.heading2`)}</h1>
                  ) : (
                    <h1>{t(`${page}.heading3`)}</h1>
                  )}
                </div>
              </div>
            </div>
            {/* Header Text - End */}
          </div>
        </div>
        {/* Header Slide - End */}
      </section>
      {/* Section End - Header */}
    </>
  );
};

export default Header;
