import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import useSequentialScripts from "../../hooks/useSequentialScripts";
import ExploreIntro from "../../global/landing-pages/ExploreIntro";
import Video from "../../global/landing-pages/Video";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { useLanguageContext } from "../../context/languageContext";
import "../styles/explore.css";

function ExploreModal(props) {
  const { t } = useLanguageContext();
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header
        closeButton={true}
        style={{
          width: "100%",
          textAlign: "center",
          display: "block",
          borderBottom: "none",
        }}
        className="explore-modal-header"
      >
        <Modal.Title id="contained-modal-title-vcenter">
          <span className="explore-modal-title1">
            {t("explore.modalTitle1")}
          </span>{" "}
          <span className="explore-modal-title2">
            {t("explore.modalTitle2")}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <Container>
          <Row>
            <Col xs={12} md={4} className="text-center">
              <Link to={t("global.youVersionOne")} target="_blank">
                <img
                  src="/img/icon-way-navy.webp"
                  alt="The WAY"
                  className="circle-image"
                />
              </Link>
            </Col>
            <Col xs={12} md={4} className="d-block d-sm-none text-center">
              <Link to={t("global.youVersionOne")} target="_blank">
                <Button
                  onClick={props.onHide}
                  className="exploreButton exploreButtonWay"
                >
                  {t("explore.modalButton1")}
                </Button>
              </Link>
            </Col>
            <Col xs={12} md={4} className="text-center">
              <Link to={t("global.youVersionTwo")} target="_blank">
                <img
                  src="/img/icon-light-navy.webp"
                  alt="The LIGHT"
                  className="circle-image"
                />
              </Link>
            </Col>
            <Col xs={12} md={4} className="d-block d-sm-none text-center">
              <Link to={t("global.youVersionTwo")} target="_blank">
                <Button
                  onClick={props.onHide}
                  className="exploreButton exploreButtonLight"
                >
                  {t("explore.modalButton2")}
                </Button>
              </Link>
            </Col>
            <Col xs={12} md={4} className="text-center">
              <Link to={t("global.youVersionThree")} target="_blank">
                <img
                  src="/img/icon-vine-navy.webp"
                  alt="The VINE"
                  className="circle-image"
                />
              </Link>
            </Col>
            <Col xs={12} md={4} className="d-block d-sm-none text-center">
              <Link to={t("global.youVersionThree")} target="_blank">
                <Button
                  onClick={props.onHide}
                  className="exploreButton exploreButtonVine"
                >
                  {t("explore.modalButton3")}
                </Button>
              </Link>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={4} className="d-none d-sm-block text-center">
              <Link to={t("global.youVersionOne")} target="_blank">
                <Button
                  onClick={props.onHide}
                  className="exploreButton exploreButtonWay"
                >
                  {t("explore.modalButton1")}
                </Button>
              </Link>
            </Col>
            <Col xs={12} md={4} className="d-none d-sm-block text-center">
              <Link to={t("global.youVersionTwo")} target="_blank">
                <Button
                  onClick={props.onHide}
                  className="exploreButton exploreButtonLight"
                >
                  {t("explore.modalButton2")}
                </Button>
              </Link>
            </Col>
            <Col xs={12} md={4} className="d-none d-sm-block text-center">
              <Link to={t("global.youVersionThree")} target="_blank">
                <Button
                  onClick={props.onHide}
                  className="exploreButton exploreButtonVine"
                >
                  {t("explore.modalButton3")}
                </Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}></Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer style={{ borderTop: "none" }}>
        <Col xs={12} md={12} className="text-center">
          <Button
            onClick={props.onHide}
            className="exploreButton exploreButtonLarge"
          >
            {t("explore.modalButton4")}
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}

function Explore() {
  const [modalShow, setModalShow] = useState(true);
  useSequentialScripts([
    "/js/jquery.min.js",
    "/js/jquery.viewportchecker.min.js",
    "/js/main.js",
  ]);

  return (
    <>
      <Helmet>
        <title>Explore | Who Am I?</title>
      </Helmet>
      {/* SECTION BEGIN - VIDEO */}
      <Video page="explore" />
      {/* Section End - Video */}

      {/* Section Start - Introduction */}
      <ExploreIntro />
      {/* Section End - Introduction */}

      {/* SECTION START - Choose Modal */}
      <ExploreModal
        dialogClassName="explore-modal"
        size="lg"
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          window.scrollTo(0, 0);
        }}
        onShow={() => setTimeout(() => window.scrollTo(0, 0), 300)}
        centered={true}
      />
      {/* SECTION END - Choose Modal */}
    </>
  );
}

export default Explore;
