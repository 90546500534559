import React from "react";
import "./styles/journey.css";
import { useLanguageContext } from "../../context/languageContext";

function Journey() {
  const { t } = useLanguageContext();
  return (
    <div className="journey-div">
      <div className="yellow-overlay"></div>
      <div className="journey-content">
        <div className="titles-box">
          <h1 className="title">{t("global.continueJourney")}</h1>
          <p className="subtitle">{t("global.exploreMore")}</p>
        </div>
        <div className="circle-images">
          <a href="/i-am-the-way" className="circle-link">
            <img
              src="/img/pin-icons-circular-way.webp"
              alt="The WAY"
              className="circle-image"
            />
            <span className="image-title">{t("global.theWay")}</span>
          </a>
          <a href="/i-am-the-light" className="circle-link">
            <img
              src="/img/pin-icons-circular-light.webp"
              alt="The LIGHT"
              className="circle-image"
            />
            <span className="image-title">{t("global.theLight")}</span>
          </a>
          <a href="/i-am-the-vine" className="circle-link">
            <img
              src="/img/pin-icons-circular-vine.webp"
              alt="The VINE"
              className="circle-image"
            />
            <span className="image-title">{t("global.theVine")}</span>
          </a>
        </div>
      </div>
    </div>
  );
}
export default Journey;
