import React from "react";
import { Helmet } from "react-helmet";
import useSequentialScripts from "../../hooks/useSequentialScripts";
import Intro from "../../global/landing-pages/Intro";
import Video from "../../global/landing-pages/Video";
import ReadMore from "../../global/landing-pages/ReadMore";
import Journey from "../../global/landing-pages/Journey";

function TheWay() {
  useSequentialScripts([
    "/js/jquery.min.js",
    "/js/jquery.viewportchecker.min.js",
    "/js/main.js",
  ]);

  return (
    <>
      <Helmet>
        <title>I Am the Way | Who Am I?</title>
      </Helmet>
      {/* Section Start - Introduction */}
      <Intro page="theWay" />
      {/* Section End - Introduction */}

      {/* SECTION BEGIN - VIDEO */}
      <Video page="theWay" triangle={true} quote="pullVerse" />
      {/* Section End - Video */}

      {/* Section Start - Read More*/}
      <ReadMore page="theWay" triangle={true} side="right" />
      {/* End section Read More */}

      {/* BEGIN SECTION Journey*/}
      <Journey />
      {/* END SECTION Journey */}
    </>
  );
}

export default TheWay;
