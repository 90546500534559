import React from "react";
import { Helmet } from "react-helmet";
import useSequentialScripts from "../../hooks/useSequentialScripts";

function VR() {
  useSequentialScripts([
    "/js/jquery.min.js",
    "/js/jquery.viewportchecker.min.js",
    "/js/main.js",
  ]);

  return (
    <>
      <Helmet>
        <title>VR Experience | Who Am I?</title>
      </Helmet>
      <section>
        <div className="container container-one">
          <div className="row">
            {/* Elegant Text - Start */}
            <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 mx-auto text-area">
              <h2 className="heading left-align title1">
                Explore The Light, The Vine, and The Way in VR
              </h2>
              <div className="headul left-align"></div>
              <p>
                Immerse yourself in a virtual world built on the spiritual truth
                of your identity.
              </p>
              <p>
                Explore this VR space where you can take an interactive journey
                through light and dark, difficult and calm, leading to a deeper
                understanding of the unconditional love of God.
              </p>
              <p>
                Embark on this virtual experience designed to transcend the
                boundaries of reality and offer a transformational adventure
                like no other.
              </p>
              <p>
                This unparalleled virtual experience will inspire, empower, and
                forever change your perspective as you explore the beauty of
                your true identity and the boundless love of the creator of the
                universe.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="landing-video-div">
          <div class="custom-triangle custom-triangle-left">
            <div class="custom-triangle-left-text">
              Jesus says "I am the resurrection and the life."
            </div>
          </div>
          <div class="vr-instructions">
            <p style={{ backgroundColor: "#13122c" }}>
              <img
                width="100%"
                src="/img/video-vrexperience.webp"
                alt="VR Experience"
              />
            </p>
          </div>
        </div>
      </section>
      <section class="vrInstructions" style={{ backgroundColor: "#13122c" }}>
        <div class="container">
          <h4 style={{ color: "#aaa" }}>
            Access the virtual world through the{" "}
            <a
              href="https://vrchat.com/home/launch?worldId=wrld_c6232f6a-1c8a-42b6-9c2f-a126ca940531"
              rel="noreferrer"
              target="_blank"
            >
              VRChat
            </a>{" "}
            app on a{" "}
            <a
              href="https://www.meta.com/experiences/pcvr/997678176960598/"
              rel="noreferrer"
              target="_blank"
            >
              headset
            </a>{" "}
            or{" "}
            <a
              href="https://store.steampowered.com/app/438100/VRChat/"
              rel="noreferrer"
              target="_blank"
            >
              PC
            </a>
          </h4>
          <a
            href="https://vrchat.com/home/launch?worldId=wrld_c6232f6a-1c8a-42b6-9c2f-a126ca940531"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src="/img/vr-instructions.jpg"
              width="100%"
              alt="VR Instructions"
              style={{ marginBottom: "25px" }}
            />
          </a>
          <h4 style={{ color: "#aaa" }}>
            On VRChat's <strong>World tab</strong>, search{" "}
            <span style={{ fontWeight: "bold", color: "#b9a555" }}>
              "Who am I to you"
            </span>
          </h4>
          <h5 style={{ color: "#aaa" }}>
            Trouble finding the world?
            <br />
            <ul>
              <li>
                Make sure your VRChat app settings will show "Community Labs."
              </li>
              <li>
                Click the Settings gear icon > Comfort & Safety > Show Community
                Labs.
              </li>
              <li>Toggle on.</li>
            </ul>
          </h5>
        </div>
      </section>
    </>
  );
}

export default VR;
