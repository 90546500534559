import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import "./App.css";
import "./i18next";
import { LanguageContextProvider } from "./context/languageContext";
import Header from "./global/header";
import Footer from "./global/footer";
import Home from "./pages/home";
import TheWay from "./pages/way";
import TheLight from "./pages/light";
import TheVine from "./pages/vine";
import Explore from "./pages/explore";
import Privacy from "./pages/privacy";
import VR from "./pages/vrexperience";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div className="home">
        <LanguageContextProvider>
          <Header page="home" />
          <Home />
          <Footer />
        </LanguageContextProvider>
      </div>
    ),
  },
  {
    path: "/i-am-the-way",
    element: (
      <div className="theWay">
        <LanguageContextProvider>
          <Header page="theWay" />
          <TheWay />
          <Footer />
        </LanguageContextProvider>
      </div>
    ),
  },
  {
    path: "/i-am-the-light",
    element: (
      <div className="theLight">
        <LanguageContextProvider>
          <Header page="theLight" />
          <TheLight />
          <Footer />
        </LanguageContextProvider>
      </div>
    ),
  },
  {
    path: "/i-am-the-vine",
    element: (
      <div className="theVine">
        <LanguageContextProvider>
          <Header page="theVine" />
          <TheVine />
          <Footer />
        </LanguageContextProvider>
      </div>
    ),
  },
  {
    path: "/explore",
    element: (
      <div className="explore">
        <LanguageContextProvider>
          <Header page="explore" />
          <Explore />
          <Footer />
        </LanguageContextProvider>
      </div>
    ),
  },
  {
    path: "/vr-experience",
    element: (
      <div className="vrexperience">
        <LanguageContextProvider>
          <Header page="vrexperience" />
          <VR />
          <Footer />
        </LanguageContextProvider>
      </div>
    ),
  },
  {
    path: "/privacy",
    element: (
      <div className="privacy">
        <LanguageContextProvider>
          <Header page="privacy" />
          <Privacy />
          <Footer />
        </LanguageContextProvider>
      </div>
    ),
  },
  {
    path: "/contact",
    element: (
      <div className="home">
        <LanguageContextProvider>
          <Header page="home" />
          <Home />
          <Footer />
        </LanguageContextProvider>
      </div>
    ),
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
