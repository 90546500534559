import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Offcanvas, ListGroup, Button } from "react-bootstrap";
import { useLanguageContext } from "../context/languageContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faInstagram,
  faTelegram,
  faFacebookMessenger,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import Icon from "@mdi/react";
import { mdiMessageTextOutline } from "@mdi/js";
import "./styles/contactSlide.css";

function ContactSlide({ format = "link", buttonText = "mainNav.contact" }) {
  const [show, setShow] = useState(false);
  const location = useLocation();

  const handleClose = () => setShow(false);
  const handleShow = (event) => {
    if (event) {
      event.preventDefault();
    }
    setShow(true);
  };

  useEffect(() => {
    if (location.pathname === "/contact") {
      document.querySelector(".contactLink a").click();
    }
  }, [location]);

  const { t, i18n } = useLanguageContext();
  const currentLanguage = i18n.language;

  return (
    <>
      <Link
        to="#"
        onClick={handleShow}
        className={format === "icon" ? "icon-link" : ""}
      >
        {format === "button" ? (
          <Button>{t(buttonText)}</Button>
        ) : format === "icon" ? (
          <>
            <Icon
              path={mdiMessageTextOutline}
              alt={`${t("global.chat")}`}
              className="icon"
            />
            <span>{t("global.chat")}</span>
          </>
        ) : (
          t(buttonText) // Fallback to just text if format is undefined or not matched
        )}
      </Link>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="bottom"
        className="contact-slide"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h3>{t("contact.contactUs")}</h3>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {currentLanguage === "zh" ? (
            <img
              src="img/chinese-contact.png"
              alt="Chinese Contact"
              height="300px"
            />
          ) : (
            <>
              <p>{t("contact.introText")}</p>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Link to="https://wa.me/+17275946264" target="_blank">
                    <FontAwesomeIcon
                      icon={faWhatsapp}
                      className="contact-icon"
                    />{" "}
                    WhatsApp
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link
                    to="https://www.facebook.com/messages/t/251997974662202/"
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faFacebookMessenger}
                      className="contact-icon"
                    />{" "}
                    Facebook Messenger
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link
                    to={`https://t.me/${t("contact.telegramID")}`}
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faTelegram}
                      className="contact-icon"
                    />{" "}
                    Telegram
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link to="https://facebook.com/globalwhoami/" target="_blank">
                    <FontAwesomeIcon
                      icon={faFacebook}
                      className="contact-icon"
                    />{" "}
                    Facebook
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link to="https://ig.me/m/globalwhoami" target="_blank">
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className="contact-icon"
                    />{" "}
                    Instagram
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default ContactSlide;
