import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

//Import all translation files
import Arabic from "./Translation/Arabic.json";
import German from "./Translation/German.json";
import English from "./Translation/English.json";
import Spanish from "./Translation/Spanish.json";
import French from "./Translation/French.json";
import Hindi from "./Translation/Hindi.json";
import Indonesian from "./Translation/Indonesian.json";
import Italian from "./Translation/Italian.json";
import Portuguese from "./Translation/Portuguese.json";
import Russian from "./Translation/Russian.json";
import Swahili from "./Translation/Swahili.json";
import Thai from "./Translation/Thai.json";
import Chinese from "./Translation/Chinese.json";

const resources = {
  ar: {
    translation: Arabic,
  },
  de: {
    translation: German,
  },
  en: {
    translation: English,
  },
  es: {
    translation: Spanish,
  },
  fr: {
    translation: French,
  },
  hi: {
    translation: Hindi,
  },
  in: {
    translation: Indonesian,
  },
  it: {
    translation: Italian,
  },
  pt: {
    translation: Portuguese,
  },
  ru: {
    translation: Russian,
  },
  sw: {
    translation: Swahili,
  },
  th: {
    translation: Thai,
  },
  zh: {
    translation: Chinese,
  },
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    load: "languageOnly",
    fallbackLng: "en",
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
      lookupQuerystring: "lng",
    },
    resources,
    rtl: ["ar", "fa"],
    // Don't set lang prop with autodetection
    // lng: "en",
  });

export default i18next;
