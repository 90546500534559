import React from "react";
import { useLanguageContext } from "../../context/languageContext";

function Intro({ page }) {
  const { t, i18n } = useLanguageContext();
  return (
    <section>
      <div className="container container-one">
        <div className="row">
          {/* Elegant Text - Start */}
          <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 mx-auto text-area">
            <h2 className="heading left-align title1">{t(`${page}.title1`)}</h2>
            <div className="headul left-align"></div>
            {i18n.exists(`${page}.paragraph1`) && (
              <p>{t(`${page}.paragraph1`)}</p>
            )}
            {i18n.exists(`${page}.paragraph2`) && (
              <p>{t(`${page}.paragraph2`)}</p>
            )}
            {i18n.exists(`${page}.paragraph3`) && (
              <p>{t(`${page}.paragraph3`)}</p>
            )}
            {i18n.exists(`${page}.paragraph4`) && (
              <p>{t(`${page}.paragraph4`)}</p>
            )}
          </div>
          {/* Elegant Text - End */}
        </div>
      </div>
    </section>
  );
}
export default Intro;
