import React from "react";
import CookieConsent from "../components/CookieConsent";
import "../components/styles/cookieConsent.css";

const Footer = () => {
  return (
    <>
      <section className="footer">
        <a href="/privacy">Privacy</a> |{" "}
        <a href="/privacy?manage-cookies=1">Cookie Preferences</a>
      </section>
      <CookieConsent />
    </>
  );
};

export default Footer;
