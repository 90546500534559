import React from "react";
import ContactSlide from "../ContactSlide";
import { useLanguageContext } from "../../context/languageContext";

function ExploreIntro() {
  const { t } = useLanguageContext();
  return (
    <section>
      <div className="container container-one">
        <div className="row">
          {/* Elegant Text - Start */}
          <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 mx-auto text-area">
            <h3 className="heading left-align title1">{t("explore.title1")}</h3>
            <div className="headul left-align"></div>
            <p>
              {t("explore.paragraph1")} <i>{t("explore.paragraph1Italics")}</i>
            </p>
            <p>{t("explore.paragraph2")}</p>
            <h3
              className="heading left-align title2"
              style={{ marginTop: "45px" }}
            >
              {t("explore.title2")}
            </h3>
            <div className="headul left-align"></div>
            <p>{t("explore.paragraph3")}</p>
            <p>{t("explore.paragraph4")}</p>
            <p>{t("explore.paragraph5")}</p>
            <h3
              className="heading left-align title3"
              style={{ marginTop: "45px" }}
            >
              {t("explore.title3")}
            </h3>
            <div className="headul left-align"></div>
            <p>{t("explore.paragraph6")}</p>
            <p>{t("explore.paragraph7")}</p>
            <p>
              <ContactSlide format="button" buttonText={t("explore.button1")} />
            </p>
          </div>
          {/* Elegant Text - End */}
        </div>
      </div>
    </section>
  );
}
export default ExploreIntro;
