import React from "react";
import { useLanguageContext } from "../../context/languageContext";
import "./styles/readMore.css";
import { Link } from "react-router-dom";
import Triangle from "../components/Triangle";
import Icon from "@mdi/react";
import ContactSlide from "../ContactSlide";
import { mdiBookOpenBlankVariantOutline } from "@mdi/js";
import { mdiMagnify } from "@mdi/js";

function ReadMore({ page, triangle = false, quote = false, side = "left" }) {
  const { t, i18n } = useLanguageContext();
  const currentLanguage = i18n.language;
  const youVersionLink =
    page === "theWay"
      ? "global.youVersionOne"
      : page === "theLight"
        ? "global.youVersionTwo"
        : page === "theVine"
          ? "global.youVersionThree"
          : "";

  side = side === "right" ? "right" : "left";
  return (
    <section>
      <div className="read-more-div">
        {triangle && <Triangle page={page} quote={quote} side={side} />}
        <div className="content-with-photo">
          <img
            src={`/img/devo-${page}-${currentLanguage}.webp`}
            alt=""
            className="content-photo"
          />
          <div className="content-text">
            <h2>{t("global.youVersionReadingPlan")}</h2>
            <Link to={t(`${youVersionLink}`)} target="_blank">
              <p className="btn">{t("global.readMore")}</p>
            </Link>
          </div>
        </div>
        <div className="icons-container">
          <ContactSlide format="icon" />
          <Link
            to="https://www.bible.com/app"
            target="_blank"
            className="icon-link"
          >
            <Icon
              path={mdiBookOpenBlankVariantOutline}
              alt={`${t("global.bibleDownload")}`}
              className="icon"
            />
            <span>{t("global.bibleDownload")}</span>
          </Link>
          <Link to="/explore" className="icon-link">
            <Icon
              path={mdiMagnify}
              alt={`${t("global.lookCloser")}`}
              className="icon"
            />
            <span>{t("global.lookCloser")}</span>
          </Link>
        </div>
      </div>
    </section>
  );
}
export default ReadMore;
