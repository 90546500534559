import React from "react";
import { Helmet } from "react-helmet";
import useSequentialScripts from "../../hooks/useSequentialScripts";
import { useLanguageContext } from "../../context/languageContext";
import "../styles/explore.css";

function Privacy() {
  useSequentialScripts([
    "/js/jquery.min.js",
    "/js/jquery.viewportchecker.min.js",
    "/js/main.js",
  ]);

  const { t } = useLanguageContext();

  return (
    <>
      <Helmet>
        <title>Home | Who Am I?</title>
      </Helmet>
      <section>
        <div className="container">
          <h3>{t("privacy.heading4")} - Adcom Development</h3>

          <p>{t("privacy.paragraph1")}</p>

          <p>{t("privacy.paragraph2")}</p>

          <h3>{t("privacy.heading5")}</h3>

          <p>{t("privacy.paragraph3")}</p>

          <p>
            <strong>{t("privacy.bold1")}</strong> {t("privacy.paragraph4")}
          </p>

          <p>
            <strong>{t("privacy.bold2")}</strong> {t("privacy.paragraph5")}
          </p>

          <ul>
            <li>
              <strong>{t("privacy.bold3")}</strong> {t("privacy.paragraph6")}
            </li>
            <li>
              <strong>{t("privacy.bold4")}</strong> {t("privacy.paragraph7")}
            </li>
          </ul>

          <h3>{t("privacy.heading6")}</h3>

          <p>{t("privacy.paragraph8")}</p>

          <p>
            <strong>{t("privacy.bold5")}</strong> {t("privacy.paragraph9")}
          </p>

          <ul>
            <li>{t("privacy.paragraph10")}</li>
          </ul>

          <p>
            <strong>{t("privacy.bold6")}</strong> {t("privacy.paragraph11")}
          </p>

          <ul>
            <li>{t("privacy.paragraph12")}</li>
            <li>{t("privacy.paragraph13")}</li>
            <li>{t("privacy.paragraph14")}</li>
          </ul>

          <p>{t("privacy.paragraph15")}</p>

          <p>
            <strong>{t("privacy.bold7")}</strong>
          </p>

          <p>{t("privacy.paragraph16")}</p>

          <p>{t("privacy.paragraph17")}</p>

          <p>{t("privacy.paragraph18")}</p>

          <p>{t("privacy.paragraph19")}</p>

          <h3>{t("privacy.heading7")}</h3>
          <p>{t("privacy.paragraph20")}</p>
        </div>
      </section>
    </>
  );
}

export default Privacy;
