import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { t } from "i18next"; //
import ContactSlide from "../ContactSlide";
import LanguageSlide from "../LanguageSlide";

const MobileMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState({});
  const location = useLocation();

  useEffect(() => {
    setMenuOpen(false);
    setSubMenuOpen({});
  }, [location]);

  const toggleMenu = () => {
    console.log(menuOpen);
    setMenuOpen(!menuOpen);
  };

  const toggleSubMenu = (index) => {
    setSubMenuOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const closeMenu = () => {
    setMenuOpen(false);
    setSubMenuOpen({});
  };

  return (
    <div
      className={`menu-mobile col-xs-10 pull-right cssmenu ${
        menuOpen ? "open" : ""
      }`}
    >
      <i
        className={`mdi ${menuOpen ? "mdi-close" : "mdi-menu"} menu-toggle`}
        onClick={toggleMenu}
      ></i>
      <ul className="menu" id="parallax-mobile-menu">
        <li>
          <Link to="/i-am-the-way">{t("mainNav.theWay")}</Link>
        </li>
        <li>
          <Link to="/i-am-the-light">{t("mainNav.theLight")}</Link>
        </li>
        <li>
          <Link to="/i-am-the-vine">{t("mainNav.theVine")}</Link>
        </li>
        <li>
          <Link to="/explore">{t("mainNav.explore")}</Link>
        </li>
        <li className="has-sub">
          <Link
            to="/"
            onClick={(e) => {
              e.preventDefault();
              toggleSubMenu(0);
            }}
          >
            {t("mainNav.resources")}{" "}
            <div>
              <i className="mdi mdi-chevron-down"></i>
            </div>
          </Link>
          <ul className={subMenuOpen[0] ? "open" : ""}>
            <li>
              <a href="https://www.bible.com/app">
                {t("global.bibleDownload")}
              </a>
            </li>
            <li>
              <a href="/vr-experience">{t("global.vrExperience")}</a>
            </li>
          </ul>
        </li>
        <li>
          <ContactSlide />
        </li>
        <LanguageSlide closeMobileMenu={closeMenu} />
      </ul>
    </div>
  );
};

export default MobileMenu;
