import React from "react";
import "./styles/triangle.css";
import { useLanguageContext } from "../../context/languageContext";

function Triangle({ page, quote = false, side = "left" }) {
  const { t } = useLanguageContext();
  return (
    <div className={`custom-triangle custom-triangle-${side}`}>
      {quote && (
        <div className={`custom-triangle-${side}-text`}>
          {t(`${page}.${quote}`)}
        </div>
      )}
    </div>
  );
}
export default Triangle;
