import React, { createContext, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const LanguageContext = createContext(undefined);

export const LanguageContextProvider = ({ children }) => {
  const languages = {
    ar: { nativeName: "العربية" },
    de: { nativeName: "Deutsch" },
    en: { nativeName: "English" },
    es: { nativeName: "Español" },
    fr: { nativeName: "Français" },
    hi: { nativeName: "हिन्दी" },
    in: { nativeName: "Indonesian" },
    it: { nativeName: "Italiano" },
    pt: { nativeName: "Portuguese" },
    ru: { nativeName: "Русский" },
    sw: { nativeName: "Kiswahili" },
    th: { nativeName: "ไทย" },
    zh: { nativeName: "中文" },
  };
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const language = i18n.language;
    const isRtl = i18n.options.rtl.includes(language);
    document.documentElement.dir = isRtl ? "rtl" : "ltr";
    document.documentElement.lang = language;
  }, [i18n.language, i18n.options.rtl]);

  const onClickLanguageChange = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <LanguageContext.Provider
      value={{ t, i18n, onClickLanguageChange, languages }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () => useContext(LanguageContext);
