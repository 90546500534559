import { useEffect, useState } from "react";

function useSequentialScripts(scripts) {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    // Ensure we have scripts to load and the current index is within bounds
    if (!scripts || currentIndex >= scripts.length) return;

    const script = document.createElement("script");
    script.src = scripts[currentIndex]; // Ensure this is correctly pointing to a URL
    script.async = true;
    script.defer = true;

    const onScriptLoad = () => {
      setCurrentIndex((prevIndex) => prevIndex + 1); // Increment index on load
    };

    script.addEventListener("load", onScriptLoad);

    document.head.appendChild(script);

    // Cleanup: Remove script and event listener
    return () => {
      script.removeEventListener("load", onScriptLoad);
      document.head.removeChild(script);
    };
  }, [currentIndex, scripts]); // Depend on currentIndex and scripts array
}

export default useSequentialScripts;
